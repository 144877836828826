import { useMemo } from 'react';
import PropTypes from 'prop-types';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

//import { useSettingsContext } from '../components/settings';

import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';
//import RTL from './options/right-to-left';
import { customShadows } from './custom-shadows';
//import { createPresets } from './options/presets';
import { componentsOverrides } from './overrides';

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  //const settings = useSettingsContext();

  //const presets = createPresets(settings.themeColorPresets);

  const memoizedValue = useMemo(
    () => ({
      palette: {
        ...palette("light"),
        //...presets.palette,
      },
      customShadows: {
        ...customShadows("light"),
        //...presets.customShadows,
      },
      //direction: settings.themeDirection,
      shadows: shadows("light"),
      shape: { borderRadius: 2 },
      typography,
    }),
    []
  );

  const theme = createTheme(memoizedValue);

  theme.components = componentsOverrides(theme);

  return (
    <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
    </MuiThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
