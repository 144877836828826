import { paths } from "../routes/paths";

// ----------------------------------------------------------------------

export const pageLinks = [
  {
    order: "1",
    subheader: "Products",
    cover: "/images/workersInTunnel.jpg",
    items: [
      { title: "Incident Severity Analysis", path: paths.products.isaHigh },
      { title: "Meta Incident Analysis - High Severity", path: paths.products.miaHigh },
      { title: "Meta Incident Analysis - Low Severity", path: paths.products.miaLow },
      { title: "Controls Analysis", path: paths.products.controlsAnalysis },
      { title: "Control Health Assessment", path: paths.products.controlsCha },
      { title: "Controls Reliability Analysis", path: paths.products.controlsRa }
    ],
  },
  {
    order: "2",
    subheader: "Case Studies",
    cover: "/images/workersInTunnel.jpg",
    items: [
      { title: "MIA in Utilities", path: paths.caseStudies.miaUtilities },
      { title: "MIA in Mining", path: paths.caseStudies.miaMining },
      { title: "MIA in Port Operations", path: paths.caseStudies.miaPort },
    ],
  },
  {
    order: "3",
    subheader: "About",
    cover: "/images/workersInTunnel.jpg",
    items: [
      { title: "About Incident Analytics", path: paths.about.ia },
      { title: "Our Team", path: paths.about.team },
      { title: "Scale", path: paths.about.scale },
      { title: "FAQ", path: paths.about.faq },
    ],
  }

];

export const navConfig = [
  { title: "Home", path: paths.home },
  { title: "Products", path: paths.products.root, children: [pageLinks[0]] },
  { title: "Training", path: paths.training },
  { title: "Studies", path: paths.caseStudies.root, children: [pageLinks[1]]},
  { title: "About", path: paths.about.root, children: [pageLinks[2]] },
];
