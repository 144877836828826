import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useRecoilValue } from "recoil";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import ListSubheader from "@mui/material/ListSubheader";
import CloseIcon from "@mui/icons-material/ChevronLeft";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GridIcon from "@mui/icons-material/Apps";
import CasesIcon from "@mui/icons-material/CasesOutlined";
import EditIcon from "@mui/icons-material/Edit";
import RuleIcon from "@mui/icons-material/Rule";
import AnalysisIcon from "@mui/icons-material/Insights";
import ActionIcon from "@mui/icons-material/TaskAlt";
import ApprovalIcon from "@mui/icons-material/Verified";
import ClientIcon from "@mui/icons-material/Business";
import OptionsetIcon from "@mui/icons-material/List";
import TenancyIcon from "@mui/icons-material/SchemaOutlined";
import UsersIcon from "@mui/icons-material/People";
import MainMenuLink from "./MainMenuLink";
import { mainMenuWidth } from "./appConstants";
import authState from "../auth/authState";
import permissionsState from "../auth/permissionsState";



export default function MainMenu({ toggleMainMenu, mainMenuOpen }) {
  const auth = useRecoilValue(authState);
  const permissions = useRecoilValue(permissionsState);
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const hasPermission = (permissionsRequired) => {
    for (var i = 0; i < permissionsRequired.length; i++) {
         if (permissions.granted[permissionsRequired[i]]) {
             return true;
         }
     }
     return false;
  }

  const hide = auth.user.role !== "client" || !hasPermission([permissions.all.projectView]);
              
  const menuItems = [
    { label: "Projects & Incidents" },
    { label: "Worklist", route: "/worklist", icon: <DashboardIcon sx={{ color: "secondary.light" }} /> },
    { label: "Incidents", route: "/incidents", icon: <GridIcon sx={{ color: "secondary.light" }} /> },
    { label: "Projects", route: "/projects", icon: <CasesIcon sx={{ color: "secondary.light" }} />, permissions: [permissions.all.projectView] },
    { label: "Bulk Import", route: "/import", icon: <EditIcon sx={{ color: "secondary.light" }} />, permissions: [permissions.all.incidentBulkUpload], hide: hide },
    { label: "Assessment", route: "/assessment", icon: <RuleIcon sx={{ color: "secondary.light" }} />, permissions: [permissions.all.incidentAssess], hide: hide },
    { label: "Analysis", route: "/analysis", icon: <AnalysisIcon sx={{ color: "secondary.light" }} />, permissions: [permissions.all.incidentAnalyse], hide: hide },
    { label: "Actions", route: "/actions", icon: <ActionIcon sx={{ color: "secondary.light" }} />, permissions: [permissions.all.incidentAction], hide: hide},
    { label: "Approval", route: "/approval", icon: <ApprovalIcon sx={{ color: "secondary.light" }} />, permissions: [permissions.all.incidentApprove], hide: hide },
    { label: "Administration", permissions: [permissions.all.clientView, permissions.all.userView, permissions.all.optionsetView] },
    { label: "Clients", route: "/clients", icon: <ClientIcon sx={{ color: "secondary.light" }} />, permissions: [permissions.all.clientView] },
    { label: "Users", route: "/users", icon: <UsersIcon sx={{ color: "secondary.light" }} />, permissions: [permissions.all.userView] },
    { label: "Optionsets", route: "/optionsets", icon: <OptionsetIcon sx={{ color: "secondary.light" }} />, permissions: [permissions.all.optionsetView] },
    { label: "Tenants", route: "/tenants", icon: <TenancyIcon sx={{ color: "secondary.light" }} />, restrictTo: ["sysadmin"] },
  ];

  return (
    Boolean(auth.user) && (
      <Drawer
        open={mainMenuOpen}
        anchor="left"
        variant={lgUp ? "persistent" : "temporary"}
        onClose={() => toggleMainMenu()}
        sx={{
          width: mainMenuWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: mainMenuWidth,
            boxSizing: "border-box",
            pb: 5,
            background: (theme) => theme.palette.background.dark,
          },
        }}
      >
        <Box>
          <Box sx={{ my: 1, ml: 2, display: "flex" }}>
            <Link to="/" style={{ display: "inline-block", height: "40px" }}>
              <img src="/images/logo-inv.png" alt="IA logo" style={{ aspectRatio: "1500/521", height: "48px" }} />
            </Link>
            <IconButton sx={{ ml: "auto", color: "secondary.light" }} size="large" edge="start" aria-label="open drawer" onClick={toggleMainMenu}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <List component="nav" sx={{ "& .MuiButtonBase-root.MuiListItemButton-root.Mui-selected": { backgroundColor: "primary.dark" } }}>
            {/*<ListSubheader>Admin</ListSubheader>*/}
            {menuItems.map((item) => {
              var component = item.route ? (
                <MainMenuLink key={item.label} label={item.label} route={item.route} toggleMainMenu={lgUp ? null : toggleMainMenu} icon={item.icon} />
              ) : (
                <ListSubheader key={"subhead-" + item.label} sx={{ color: "primary.contrastText", background: "transparent" }}>
                  {item.label}
                </ListSubheader>
              );

              if (item.restrictTo && item.restrictTo.indexOf(auth.user.role) === -1) component = null;
              
              if (item.permissions && !hasPermission(item.permissions)) component = null;
              
              if (item.hide) component = null;

              return component;
            })}
          </List>
        </Box>
      </Drawer>
    )
  );
}
