import React from "react";
import { useLocation } from 'react-router-dom';
import Box from "@mui/material/Box";
import { MotionLazy } from "./components/animate/motion-lazy";
import Header from "./Header";
import Footer from "./Footer";
import ThemeProvider from "./theme";

export default function Website({ children }) {
  
  const location = useLocation();
  
  return (
    <ThemeProvider>
      <MotionLazy>
        <Box>
          <Header headerOnPhoto={location.pathname === "/"} />
          {children}
          <Footer />
        </Box>
      </MotionLazy>
    </ThemeProvider>
  );
}
