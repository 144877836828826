import React from "react";
import { Link } from "react-router-dom";
import Chip from "@mui/material/Chip";
import { emphasize } from "@mui/material/styles";
// import Typography from "@mui/material/Typography";
// import theme from "../theme";

export default function Breadcrumb({ to, label, icon, deleteIcon, onDelete }) {

  return (
    <Chip
      { ...(to && to !== "tenant" ? { component: Link, to } : {}) }
      sx={(theme) => ({
        backgroundColor: "background.white",
        height: theme.spacing(3),
        cursor: to ? "pointer" : "default",
        color: to ? theme.palette.primary.main : theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        ...(to
          ? {
              "&:hover, &:focus": {
                backgroundColor: emphasize(theme.palette.background.mainLight, 0.06),
              },
              "&:active": {
                boxShadow: theme.shadows[1],
                backgroundColor: emphasize(theme.palette.background.mainLight, 0.12),
              },
            }
          : {}),
      })}
      label={label}
      icon={icon ?? null}
      onDelete={onDelete}
      deleteIcon={deleteIcon}
    />
  );
}
