import React, { Fragment, Suspense, lazy, useEffect } from "react";
import { Route, Routes as ReactRouterRoutes } from "react-router-dom";
import { useRecoilState } from "recoil";
import AuthorizeRoute from "./auth/AuthorizeRoute";
// import TenantRoute from "./auth/TenantRoute";
// import AdminRoute from "./auth/AdminRoute";
import PermissionRoute from "./auth/PermissionRoute";
import SysAdminRoute from "./auth/SysAdminRoute";
//import ApiAuthorizationRoutes from "./auth/ApiAuthorizationRoutes";
import PageLoader from "./app/PageLoader";
import authState from "./auth/authState";
import permissionsState from "./auth/permissionsState";
import fetchSimple from "./services/fetchSimple";
import authService from "./auth/AuthorizeService";
import App from "./App";
import { websiteRoutes } from "./website/routes/WebsiteRoutes";
import { ApplicationPaths, LoginActions, LogoutActions } from "./auth/ApiAuthorizationConstants";

import Login from "./auth/Login";
import Logout from "./auth/Logout";

// App pages
const OverviewPage = lazy(() => import("./overview/OverviewPage"));
const IncidentPage = lazy(() => import("./incidents/IncidentPage"));
const IncidentsPage = lazy(() => import("./incidents/IncidentsPage"));
const IncidentEntryPage = lazy(() => import("./incidents/IncidentEntryPage"));
const IncidentPhasePage = lazy(() => import("./incidents/IncidentPhasePage"));
const ClientsPage = lazy(() => import("./clients/ClientsPage"));
const ProjectsPage = lazy(() => import("./projects/ProjectsPage"));
const ProjectPage = lazy(() => import("./projects/ProjectPage"));
const TenantsPage = lazy(() => import("./tenants/TenantsPage"));
const OptionsetsPage = lazy(() => import("./optionsets/OptionsetsPage"));
const UsersPage = lazy(() => import("./users/UsersPage"));
const SettingsPage = lazy(() => import("./settings/SettingsPage"));

export default function Routes(props) {
  const [auth, setAuth] = useRecoilState(authState);
  const [permissions, setPermissions] = useRecoilState(permissionsState);
  const isClientRole = auth.user?.role === "client";

  useEffect(() => {
    async function populateRecoilState() {
      const [isAuthenticated, usr, token] = await Promise.all([authService.isAuthenticated(), authService.getUser(), authService.getAccessToken()]);
      setAuth({
        authenticated: isAuthenticated,
        user: usr,
        token: token,
        fetching: false,
      });
    }

    App.subscription = authService.subscribe(() => populateRecoilState());
    populateRecoilState();
    return function cleanup() {
      authService.unsubscribe(App.subscription);
    };
  }, [setAuth]);

  useEffect(() => {
    function fetchPermissions() {
      fetchSimple("/api/permissions", "GET", auth.token, null, setPermissions);
    }
    fetchPermissions();
  }, [auth, setPermissions]);

  const projectsRoutes = [
    "/projects/:projectId",
    "/projects/:projectId/incidents",
    "/projects/:projectId/import",
    "/projects/:projectId/assessment",
    "/projects/:projectId/analysis",
    "/projects/:projectId/actions",
    "/projects/:projectId/approval",
  ];

  return (
    <Suspense fallback={<PageLoader />}>
      <ReactRouterRoutes>
        {websiteRoutes}

        <Route
          path="/worklist"
          element={
            <AuthorizeRoute>
              <App {...props}>
                <OverviewPage />
              </App>
            </AuthorizeRoute>
          }
        />

        <Route
          path="/incidents"
          element={
            <AuthorizeRoute>
              <App {...props}>
                <IncidentsPage />
              </App>
            </AuthorizeRoute>
          }
        />

        <Route
          path="/incidents/:incidentId"
          element={
            <AuthorizeRoute>
              <App {...props}>
                <IncidentPage />
              </App>
            </AuthorizeRoute>
          }
        />


        {permissions.granted && (
          <Fragment>

            <Route
                path="/projects/:projectId/incidents/:incidentId"
                element={
                  <AuthorizeRoute>
                    <PermissionRoute permissionsRequired={[permissions.all.projectView]}>
                      <App {...props}>
                        <IncidentPage />
                      </App>
                    </PermissionRoute>
                  </AuthorizeRoute>
                }
              />

            <Route
              path="/projects"
              element={
                <AuthorizeRoute>
                  <PermissionRoute permissionsRequired={[permissions.all.projectView]}>
                    <App {...props}>
                      <ProjectsPage />
                    </App>
                  </PermissionRoute>
                </AuthorizeRoute>
              }
            />

            {projectsRoutes.map((route) => (
              <Route
                key={route}
                path={route}
                element={
                  <AuthorizeRoute>
                    <PermissionRoute permissionsRequired={[permissions.all.projectView]}>
                      <App {...props}>
                        <ProjectPage />
                      </App>
                    </PermissionRoute>
                  </AuthorizeRoute>
                }
              />
            ))}

            <Route
              path="/import"
              element={
                <AuthorizeRoute>
                  <PermissionRoute permissionsRequired={[permissions.all.incidentBulkUpload]}>
                    <App {...props}>
                      <IncidentEntryPage />
                    </App>
                  </PermissionRoute>
                </AuthorizeRoute>
              }
            />

            <Route
              path="/clients"
              element={
                <AuthorizeRoute>
                  <PermissionRoute permissionsRequired={[permissions.all.clientView]}>
                    <App {...props}>
                      <ClientsPage />
                    </App>
                  </PermissionRoute>
                </AuthorizeRoute>
              }
            />

            <Route
              path="/users"
              element={
                <AuthorizeRoute>
                  <PermissionRoute permissionsRequired={[permissions.all.userView]}>
                    <App {...props}>
                      <UsersPage />
                    </App>
                  </PermissionRoute>
                </AuthorizeRoute>
              }
            />

            <Route
              path="/optionsets"
              element={
                <AuthorizeRoute>
                  <PermissionRoute permissionsRequired={[permissions.all.optionsetView]}>
                    <App {...props}>
                      <OptionsetsPage />
                    </App>
                  </PermissionRoute>
                </AuthorizeRoute>
              }
            />
          </Fragment>
        )}

        {permissions.granted && isClientRole && (
          <Fragment>
            <Route
              path="/assessment"
              element={
                <AuthorizeRoute>
                  <PermissionRoute permissionsRequired={[permissions.all.incidentAssess]}>
                    <App {...props}>
                      <IncidentPhasePage phase={"assessment"} />
                    </App>
                  </PermissionRoute>
                </AuthorizeRoute>
              }
            />

            <Route
              path="/analysis"
              element={
                <AuthorizeRoute>
                  <PermissionRoute permissionsRequired={[permissions.all.incidentAnalyse]}>
                    <App {...props}>
                      <IncidentPhasePage phase={"analysis"} />
                    </App>
                  </PermissionRoute>
                </AuthorizeRoute>
              }
            />

            <Route
              path="/actions"
              element={
                <AuthorizeRoute>
                  <PermissionRoute permissionsRequired={[permissions.all.incidentAction]}>
                    <App {...props}>
                      <IncidentPhasePage phase={"actions"} />
                    </App>
                  </PermissionRoute>
                </AuthorizeRoute>
              }
            />

            <Route
              path="/approval"
              element={
                <AuthorizeRoute>
                  <PermissionRoute permissionsRequired={[permissions.all.incidentApprove]}>
                    <App {...props}>
                      <IncidentPhasePage phase={"approval"} />
                    </App>
                  </PermissionRoute>
                </AuthorizeRoute>
              }
            />
          </Fragment>
        )}

        <Route
          path="/tenants"
          element={
            <AuthorizeRoute>
              <SysAdminRoute>
                <App {...props}>
                  <TenantsPage />
                </App>
              </SysAdminRoute>
            </AuthorizeRoute>
          }
        />

        <Route
          path="/settings"
          element={
            <AuthorizeRoute>
              <App {...props}>
                <SettingsPage />
              </App>
            </AuthorizeRoute>
          }
        />

        <Route path={ApplicationPaths.Login} element={<Login action={LoginActions.Login} />} />
        <Route path={ApplicationPaths.LoginFailed} element={<Login action={LoginActions.LoginFailed} />} />
        <Route path={ApplicationPaths.LoginCallback} element={<Login action={LoginActions.LoginCallback} />} />
        <Route path={ApplicationPaths.Profile} element={<Login action={LoginActions.Profile} />} />
        <Route path={ApplicationPaths.Register} element={<Login action={LoginActions.Register} />} />
        <Route path={ApplicationPaths.LogOut} element={<Logout action={LogoutActions.Logout} />} />
        <Route path={ApplicationPaths.LogOutCallback} element={<Logout action={LogoutActions.LogoutCallback} />} />
        <Route path={ApplicationPaths.LoggedOut} element={<Logout action={LogoutActions.LoggedOut} />} />
      </ReactRouterRoutes>
    </Suspense>
  );
}
