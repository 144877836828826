import { lazy } from "react";
import { Route } from "react-router-dom";
import { paths } from "./paths";
import Website from "../Website";

// ----------------------------------------------------------------------

const AboutIaPage = lazy(() => import("../pages/about/AboutIaPage"));
const AboutTeamPage = lazy(() => import("../pages/about/AboutTeamPage"));
const ControlsAnalysisPage = lazy(() => import("../pages/products/ControlsAnalysisPage"));
const ControlsChaPage = lazy(() => import("../pages/products/ControlsChaPage"));
const ControlsRaPage = lazy(() => import("../pages/products/ControlsRaPage"));
const FaqPage = lazy(() => import("../pages/about/FaqPage"));
const HomePage = lazy(() => import("../pages/home/HomePage"));
const IsaHighPage = lazy(() => import("../pages/products/IsaHighPage"));
const MiaHighPage = lazy(() => import("../pages/products/MiaHighPage"));
const MiaLowPage = lazy(() => import("../pages/products/MiaLowPage"));
const ScalePage = lazy(() => import("../pages/about/ScalePage"));
const MiaUtilitiesPage = lazy(() => import("../pages/case-studies/MiaUtilitiesPage"));
const MiaMiningPage = lazy(() => import("../pages/case-studies/MiaMiningPage"));
const MiaPortPage = lazy(() => import("../pages/case-studies/MiaPortPage"));
const TrainingPage = lazy(() => import("../pages/training/TrainingPage"));

// -----------------------------------------------------------------------

export const websiteRoutes = [
  <Route
    path={paths.home}
    key={paths.home}
    element={
      <Website>
        <HomePage />
      </Website>
    }
  />,
  <Route
    path={paths.products.isaHigh}
    key={paths.products.isaHigh}
    element={
      <Website>
        <IsaHighPage />
      </Website>
    }
  />,
  <Route
    path={paths.products.miaHigh}
    key={paths.products.miaHigh}
    element={
      <Website>
        <MiaHighPage />
      </Website>
    }
  />,
  <Route
    path={paths.products.miaLow}
    key={paths.products.miaLow}
    element={
      <Website>
        <MiaLowPage />
      </Website>
    }
  />,
  <Route
    path={paths.products.controlsAnalysis}
    key={paths.products.controlsAnalysis}
    element={
      <Website>
        <ControlsAnalysisPage />
      </Website>
    }
  />,
  <Route
    path={paths.products.controlsCha}
    key={paths.products.controlsCha}
    element={
      <Website>
        <ControlsChaPage />
      </Website>
    }
  />,
  <Route
    path={paths.products.controlsRa}
    key={paths.products.controlsRa}
    element={
      <Website>
        <ControlsRaPage />
      </Website>
    }
  />,
  <Route
    path={paths.about.ia}
    key={paths.about.ia}
    element={
      <Website>
        <AboutIaPage />
      </Website>
    }
  />,
  <Route
    path={paths.about.team}
    key={paths.about.team}
    element={
      <Website>
        <AboutTeamPage />
      </Website>
    }
  />,
  <Route
    path={paths.about.scale}
    key={paths.about.scale}
    element={
      <Website>
        <ScalePage />
      </Website>
    }
  />,
  <Route
    path={paths.about.faq}
    key={paths.about.faq}
    element={
      <Website>
        <FaqPage />
      </Website>
    }
  />,
  <Route
    path={paths.caseStudies.miaUtilities}
    key={paths.caseStudies.miaUtilities}
    element={
      <Website>
        <MiaUtilitiesPage />
      </Website>
    }
  />,
  <Route
    path={paths.caseStudies.miaMining}
    key={paths.caseStudies.miaMining}
    element={
      <Website>
        <MiaMiningPage />
      </Website>
    }
  />,
  <Route
    path={paths.caseStudies.miaPort}
    key={paths.caseStudies.miaPort}
    element={
      <Website>
        <MiaPortPage />
      </Website>
    }
  />,
  <Route
    path={paths.training}
    key={paths.training}
    element={
      <Website>
        <TrainingPage />
      </Website>
    }
  />,
];
