import './global.css';
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { CacheProvider } from "@emotion/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "allotment/dist/style.css";
import theme from "./theme";
import createEmotionCache from "./createEmotionCache.js";
import ScrollToTop from "./ScrollToTop";
import Routes from "./Routes";
//import registerServiceWorker from './registerServiceWorker';
import '@fontsource/plus-jakarta-sans/300.css';
import '@fontsource/plus-jakarta-sans/400.css';
import '@fontsource/plus-jakarta-sans/500.css';
import '@fontsource/plus-jakarta-sans/600.css';
import '@fontsource/plus-jakarta-sans/700.css';
import '@fontsource/plus-jakarta-sans/300-italic.css';
import '@fontsource/plus-jakarta-sans/400-italic.css';
import '@fontsource/plus-jakarta-sans/500-italic.css';
import '@fontsource/plus-jakarta-sans/600-italic.css';
import '@fontsource/plus-jakarta-sans/700-italic.css';

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

LicenseInfo.setLicenseKey("799f59ab8943ca8174e2b88c1a2626dcTz03OTgzOSxFPTE3MzMwOTk0MzQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y");

const responsiveTheme = responsiveFontSizes(theme);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

root.render(
  <CacheProvider value={clientSideEmotionCache}>
    <DndProvider backend={HTML5Backend}>
      <ThemeProvider theme={responsiveTheme}>
        <CssBaseline />
        <BrowserRouter basename={baseUrl}>
          <RecoilRoot>
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
          </RecoilRoot>
        </BrowserRouter>
      </ThemeProvider>
    </DndProvider>
  </CacheProvider>
);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();
