import React from 'react'
import { useRecoilValue } from 'recoil';
import { Navigate, useLocation } from 'react-router-dom'
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants'
import authState from "./authState";


export default function AuthorizeRoute({ children, ...rest } ) {

    const auth = useRecoilValue(authState)
    const location = useLocation()

    var link = document.createElement("a");
    link.href = location.pathname;
    const returnUrl = `${link.protocol}//${link.host}${link.pathname}${link.search}${link.hash}`;
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(returnUrl)}`;

    return auth.authenticated ? children : <Navigate to={redirectUrl} />;

}
