import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
//import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
//import InputAdornment from "@mui/material/InputAdornment";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import TenancyIcon from "@mui/icons-material/SchemaOutlined";
//import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import fetchSimple from "../services/fetchSimple";
import authState from "../auth/authState";
import Breadcrumb from "../app/Breadcrumb";

export default function TenantsBreadcrumb({ sx }) {
  const auth = useRecoilValue(authState);
  const [open, ] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [, setTenants] = useState([]);
  const [, setFetching] = useState({ fetching: true });

  useEffect(() => {
    async function fetchTenants() {
      await fetchSimple("/api/tenants", "GET", auth.token, setFetching, setTenants)
        .then((data) => {
          data.forEach((tenant) => {
            if (tenant.isCurrent) setSelectedTenant(tenant);
          });
        })
        .finally(() => setFetching(false));
    }
    fetchTenants();
  }, [auth.token]);

  // const handleTenantChange = async (tenant) => {
  //   setSelectedTenant(tenant);
  //   setOpen(true);
  //   fetchSimple(`/api/tenants/switch/${tenant.id}`, "GET", auth.token, null, null).then(() => {
  //     window.location.reload();
  //   });
  // };

  return (
    <Box>
      <Breadcrumb 
        to={"tenant"}
        label={selectedTenant?.name}
        deleteIcon={<ExpandMoreIcon />}
        onDelete={() => {}} />
      {/* <Autocomplete
        value={selectedTenant}
        disablePortal
        disableClearable
        options={tenants}
        getOptionLabel={(option) => (Boolean(option.name) ? option.name : "Select tenant")}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            variant="outlined"
            color="secondary"
            sx={{
              "& .MuiInputBase-root": { backgroundColor: "background.input" },
              "& .MuiInputBase-root:hover": { backgroundColor: "background.mainLight" },
              "& .MuiInputBase-root.Mui-focused": { backgroundColor: "background.main" },
            }}
            placeholder="Select tenant"
            hiddenLabel
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <TenancyIcon />
                </InputAdornment>
              ),
            }}
            size="small"
          />
        )}
        onChange={(_, tenant) => handleTenantChange(tenant)}
      /> */}
      <Modal open={open} onClose={() => {}}>
        <Box sx={{ display: "flex", alignItems: "center", height: 1, width: 1, justifyContent: "center" }}>
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={80} sx={{ color: (theme) => theme.palette.common.white }} color="primary" disableShrink />
            <Typography sx={{ ml: 3, fontSize: "3rem", color: (theme) => theme.palette.common.white }}>Switching Tenant</Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
