import React, { Suspense, lazy } from "react";
import { useRecoilState } from "recoil";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CloseIcon from "@mui/icons-material/Close";
import helpState from "./helpState";
import Loader from "../app/Loader";

const HelpPages = lazy(() => import("./HelpPages"));

export default function HelpDrawer({ variant, anchor, drawerSize, children }) {
  const [help, setHelp] = useRecoilState(helpState);

  const dimensions = {
    width: drawerSize.right ? drawerSize.right : drawerSize,
    top: { xs: "56px", sm: "64px" },
    height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
    [`& .MuiDrawer-paper`]: {
      width: drawerSize.right ? drawerSize.right : drawerSize,
      boxSizing: "border-box",
      top: { xs: "56px", sm: "64px" },
      height: { xs: "calc(100vh - 56px)", sm: "calc(100vh - 64px)" },
    },
  };

  return (
    <Drawer
      open={Boolean(help)}
      anchor={anchor ?? "right"}
      variant={variant ? variant : "persistent"}
      onClose={() => setHelp(false)}
      PaperProps={{ sx: { overflowY: "hidden" } }}
      sx={{
        ...dimensions,
        flexShrink: 0,
        overflow: "hidden",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          pl: 2,
          bgcolor: "background.greyLight",
          borderBottom: 1,
          borderBottomColor: "border.main",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            alignSelf: "center",
            pr: 2,
            fontWeight: "regular",
          }}
        >
          User guide
        </Typography>
        <Box sx={{ flex: "1" }}></Box>
        <IconButton sx={{ mr: -1 }} onClick={() => setHelp(false)}>
          <CloseIcon />
        </IconButton>
      </Toolbar>

      {help !== true && (
        <Box sx={{ flex: 0, pl: 4, py: 2 }}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="primary"
              href=""
              onClick={(e) => {
                e.preventDefault();
                setHelp(true);
              }}
            >
              Index
            </Link>
            <Typography color="text.primary">{help.page}</Typography>
          </Breadcrumbs>
        </Box>
      )}
      <Suspense fallback={<Loader />}>{help !== false && <HelpPages />}</Suspense>
    </Drawer>
  );
}
