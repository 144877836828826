import React, { Fragment } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Link, useResolvedPath } from "react-router-dom";
import { AppBar as MuiAppBar } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
//import Typography from "@mui/material/Typography";
//import MuiLink from '@mui/material/Link';
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import HelpIcon from "@mui/icons-material/HelpOutlined";
//import HomeIcon from "@mui/icons-material/HomeOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountMenu from "../auth/AccountMenu";
import TenantsBreadcrumb from "../tenants/TenantsBreadcrumb";
import { mainMenuWidth, routeTitles } from "./appConstants";
import Breadcrumb from "./Breadcrumb";
import helpState from "../help/helpState";
import authState from "../auth/authState";
import entityContextState from "../entityContextState";

export default function AppBar({ authenticated, toggleMainMenu, mainMenuOpen, lgUp, showTenantSelection }) {
  
  const [help, setHelp] = useRecoilState(helpState);
  const auth = useRecoilValue(authState);
  const entityContext = useRecoilValue(entityContextState);
  const routeMatch = useResolvedPath("").pathname;
  const crumbs = routeMatch.split("/");

  const getCrumbPath = (index) => {
    return crumbs.slice(0, index + 1).join("/");
  };

  const handleHelpClick = () => {
    setHelp(!help);
  };


  return (
    <MuiAppBar
      sx={{
        flexGrow: 1,
        bgcolor: "transparent",
        boxShadow: 0,
        //zIndex: (theme) => theme.zIndex.drawer + 1,
        transition: (theme) =>
          theme.transitions.create(["marginLeft", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        marginLeft: 0,
        ...(mainMenuOpen && {
          width: `calc(100% - ${mainMenuWidth}px)`,
          marginLeft: `${mainMenuWidth}px`,
          transition: (theme) =>
            theme.transitions.create(["marginLeft", "width"], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
        }),
      }}
    >
      <Toolbar>
        {!mainMenuOpen && (
          <Fragment>
            {authenticated && (
              <IconButton size="large" edge="start" color="inherit" aria-label="open drawer" sx={{ position: "absolute" }} onClick={toggleMainMenu}>
                <MenuIcon color="primary" />
              </IconButton>
            )}
            <Box sx={{ ml: authenticated ? 6 : 0, mr: 4, display: "flex" }}>
              <Link to="/" style={{ display: "inline-block", height: "39px" }}>
                <img src="/images/logo.png" alt="IA logo" style={{ aspectRatio: "500/154", height: "39px" }} />
              </Link>
            </Box>
          </Fragment>
        )}

        <Breadcrumbs sx={{ ml: -1.5, "& .MuiBreadcrumbs-separator": { m: 0 } }} aria-label="breadcrumb">
          {/* {mainMenuOpen && <Breadcrumb icon={<HomeIcon fontSize="small" />} label={null} />} */}
          {showTenantSelection && <TenantsBreadcrumb />}
          {routeMatch.indexOf(":") !== -1 && auth.user?.role !== "client" && <Breadcrumb label={entityContext?.clientName} />}
          {crumbs.map((crumb, index) => {
            if (index === 0) return null;
            if (crumb.startsWith(":")) {
              return <Breadcrumb to={index !== crumbs.length -1 ? getCrumbPath(index) : null} key={index} label={entityContext?.[crumb.replace(":projectId", index !== crumbs.length -1 ? "projectName" : "name").replace(":incidentId", "eventTitle")]} />
            }
            return <Breadcrumb to={index !== crumbs.length -1 ? getCrumbPath(index) : null} key={index} label={routeTitles[crumb]} />;
          })}
        </Breadcrumbs>

        {authenticated && (
          <Box sx={{ ml: "auto", display: "flex", alignItems: "center" }}>
            {/* {showTenantSelection && <TenantsBreadcrumb sx={{ minWidth: 260 }} />} */}
            <IconButton size="large" onClick={handleHelpClick} color="primary" sx={{ ml: 2 }}>
              <HelpIcon />
            </IconButton>
            <IconButton color="primary" size="large" component={Link} to="/settings" sx={{ ml: 1 }}>
              <SettingsIcon />
            </IconButton>
            <AccountMenu />
          </Box>
        )}
      </Toolbar>
    </MuiAppBar>
  );
}
