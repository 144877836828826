import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
//import Routes from "./Routes.js";
import AppBar from "./app/AppBar";
import MainMenu from "./app/MainMenu";
import authState from "./auth/authState";
import permissionsState from "./auth/permissionsState";
import snackbarState from "./app/snackbarState";
import { mainMenuWidth, detailDrawerSize } from "./app/appConstants";
import Loader from "./app/Loader.js";
import HelpDrawer from "./help/HelpDrawer.js";

export default function App({children}) {

  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [mainMenuOpen, setMainMenuOpen] = useState(lgUp);
  const auth = useRecoilValue(authState);
  const permissions = useRecoilValue(permissionsState);
  const [snackbar, setSnackbar] = useRecoilState(snackbarState);

  const toggleMainMenu = () => {
    setMainMenuOpen(!mainMenuOpen);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbar(false);
  };

  return auth.fetching ? (
    <Loader />
  ) : (
    <Box
      sx={{
        bgcolor: "background.white",
        position: "relative",
        overflow: "hidden",
        height: "100vh",
      }}
    >
      <AppBar
        toggleMainMenu={toggleMainMenu}
        authenticated={auth.authenticated}
        showTenantSelection={auth.user?.role === "sysadmin"}
        mainMenuOpen={auth.authenticated && mainMenuOpen}
        lgUp={lgUp}
      />

      <Box
        sx={(theme) => {
          return {
            transition: () =>
              theme.transitions.create(["marginLeft"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            marginLeft: 0,
            ...(auth.authenticated &&
              mainMenuOpen && {
                [`${theme.breakpoints.up("lg")}`]: { marginLeft: `${mainMenuWidth}px` },
                transition: (theme) =>
                  theme.transitions.create(["marginLeft"], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
              }),
          };
        }}
      >
        {/* <MasterDetailPanel detailsOpen={!!help} drawerSize={detailDrawerSize}> */}
          {children}
        {/* </MasterDetailPanel> */}
        <HelpDrawer drawerSize={detailDrawerSize} />
      </Box>

      <Snackbar
        open={Boolean(snackbar)}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert elevation={6} onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {auth.authenticated && permissions.granted && <MainMenu toggleMainMenu={toggleMainMenu} mainMenuOpen={mainMenuOpen} />}
    </Box>
  );
}
