import PropTypes from 'prop-types';

import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';

import { useOffSetTop } from './hooks/use-off-set-top';
import { useResponsive } from './hooks/use-responsive';

import { bgBlur } from './css';

import Logo from './components/logo';

import WhiteButton from './components/whiteButton';

import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { HEADER } from './nav/config-layout';
//import Searchbar from '../common/searchbar';
import { navConfig } from './nav/config-navigation';
import HeaderShadow from './nav/header-shadow';
//import SettingsButton from '../common/settings-button';
import { ApplicationPaths } from "../auth/ApiAuthorizationConstants";

// ----------------------------------------------------------------------

export default function Header({ headerOnPhoto }) {
  const theme = useTheme();

  const offset = useOffSetTop();

  const mdUp = useResponsive('up', 'md');
  //const lgUp = useResponsive('up', 'lg');

  const renderContent = (
    <>
      <Box sx={{ lineHeight: 0, position: 'relative' }}>
        <Logo inverse />
      </Box>

      <>
        <Stack
          flexGrow={1}
          alignItems="center"
          sx={{
            height: 1,
            display: { xs: 'none', md: 'flex' },
          }}
        >
          <NavDesktop data={navConfig} />
        </Stack>

        <Box sx={{ flexGrow: { xs: 1, md: 'unset' } }} />
      </>

      <Stack spacing={2} direction="row" alignItems="center" justifyContent="flex-end">
        <WhiteButton
                      component={Link}
                      to={ApplicationPaths.Login}
          variant="contained"
            inverse
            sx={{
              display: { xs: 'none', md: 'inline-flex' },
            }}
          >
            Sign In
        </WhiteButton>

        {/* {lgUp && <WhiteButton
          variant="contained"
          href={paths.zoneStore}
          sx={{
            display: { xs: 'none', md: 'inline-flex' },
          }}
        >
          Get Started
        </WhiteButton>} */}
      </Stack>

      {!mdUp && <NavMobile data={navConfig} />}
    </>
  );

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...bgBlur({ color: theme.palette.background.dark, opacity: headerOnPhoto ? 0.5 : 1}),
          color: 'common.white',
          ...(offset && {
            ...bgBlur({ color: theme.palette.background.dark, opacity: 1 }),
            //color: 'text.primary',
            height: {
              md: HEADER.H_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container
          sx={{
            height: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {renderContent}
        </Container>
      </Toolbar>

      {offset && <HeaderShadow />}
    </AppBar>
  );
}

Header.propTypes = {
  headerOnDark: PropTypes.bool,
};
