import Button from "@mui/material/Button";
import { darken, lighten } from "@mui/material/styles";

// ----------------------------------------------------------------------

export default function WhiteButton({ sx, inverse, children, ...other }) {
  return (
    <Button
      {...other}
      sx={{
        color: inverse ? "common.white" : "background.dark",
        bgcolor: inverse ? "background.dark" : "common.white",
        "&:hover": { bgcolor: (theme) => inverse ? lighten(theme.palette.background.dark, 0.1) : darken(theme.palette.common.white, 0.1)},
        ...sx,
      }}
    >
      {children}
    </Button>
  );
}
