// ----------------------------------------------------------------------

export const paths = {

  products: {
    root: '/products',
    isaHigh: '/products/isa-high',
    miaHigh: '/products/mia-high',
    miaLow: '/products/mia-low',
    controlsAnalysis: '/products/controls-analysis',
    controlsCha: '/products/controls-cha',
    controlsRa: '/products/controls-ra',
  },
  
  //Products - ISA
  // isa: {
  //   root: '/products',
  //   isaHigh: '/products/isa-high',
  //   isaActionableInsights: '/products/isa-actionable-insights',
  //   isaLow: '/products/isa-low',
  //   isaAssurance: '/products/isa-assurance',
  // },
  
  // //Products - MIA
  // mia: {
  //   root: '/products',
  //   miaHigh: '/products/mia-high',
  //   miaAnalytics: '/products/mia-analytics',
  //   miaLow: '/products/mia-low',
  //   miaAssurance: '/products/mia-assurance',
  // },
  
  // //Products - Controls
  // controls: {
  //   root: '/products',
  //   controlsAnalysis: '/products/controls-analysis',
  //   controlsCha: '/products/controls-cha',
  //   controlsRa: '/products/controls-ra',
  // },
  
  //Case Studies
  caseStudies: {
    root: '/case-studies',
    miaUtilities: '/case-studies/mia-utilities',
    miaMining: '/case-studies/mia-mining',
    miaPort: '/case-studies/mia-port',
  },

  about: {
    root: '/about',
    ia: '/about/ia',
    team: '/about/team',
    scale: '/about/scale',
    faq: '/about/faq',
  },

  home: '/',
  training: '/training',
};
