import React from "react";
import Box from "@mui/material/Box";

export default function ScrollContainer({ children, sx, ...rest }) {
  return (
    <Box
      sx={{
        mt: { xs: 6, sm: 7 },
        position: "relative",
        height: { xs: "calc(100vh - 48px)", sm: "calc(100vh - 56px)" },
        overflow: "auto",
        // "&::-webkit-scrollbar": {
        //   width: "8px",
        //   height: "8px",
        // },
        // "&::-webkit-scrollbar-track": {
        //   bgcolor: "transparent",
        // },
        // "&::-webkit-scrollbar-thumb": {
        //   bgcolor: "transparent",
        //   borderRadius: 4,
        // },
        // "&:hover::-webkit-scrollbar-thumb": {
        //   bgcolor: "divider",
        // },
        // "&::-webkit-scrollbar-thumb:hover": {
        //   bgcolor: "primary.light",
        // },
        ...sx,
      }}
      id="pageScroll"
      {...rest}
    >
      {children}
    </Box>
  );
}
